export const userKeys = {
    all: ["user"],
    details: () => [...userKeys.all, "details"],
};

export const applicationKeys = {
    all: ["application"],
    featureFlags: () => [...applicationKeys.all, "feature-flags"],
};

export const mailbotKeys = {
    all: ["mailbot"],
    statistics: () => [...mailbotKeys.all, "statistics"],
    statisticsForLatestSubscription: () => [...mailbotKeys.statistics(), "latest-subscription"],
    mailbotProfile: () => [...mailbotKeys.all, "mailbot-profile"],
    canEnable: () => [...mailbotKeys.mailbotProfile(), "can-enable"], // data in profile details
    canDisable: () => [...mailbotKeys.mailbotProfile(), "can-disable"], // data in profile details
    isActive: () => [...mailbotKeys.mailbotProfile(), "is-active"], // data in profile details
    showNewUIPopup: () => [...mailbotKeys.all, "show-new-ui-popup"],
    showNewPricingPlans: () => [...mailbotKeys.all, "show-new-pricing-plans"],
    senderProfiles: () => [...mailbotKeys.all, "sender-profiles"],
    autoCleanerPage: ({ page, queryParams }) => [...mailbotKeys.senderProfiles(), "auto-cleaner", page, queryParams],
    bulkDeletePage: ({ page, queryParams }) => [...mailbotKeys.senderProfiles(), "bulk-delete", page, queryParams],
    unsubscribePage: ({ page, queryParams }) => [...mailbotKeys.senderProfiles(), "unsubscribe", page, queryParams],
    historicalEmailsCount: ({ senderEmail }) => [...mailbotKeys.all, "historical-emails-count", senderEmail],
    deleteHistoricalEmails: () => [...mailbotKeys.all, "delete-historical-emails"], // Key for deleteHistoricalEmails mutation
};

export const paymentKeys = {
    all: ["payment"],
    pricings: () => [...paymentKeys.all, "pricings"],
    latestSubscription: () => [...paymentKeys.all, "latest-subscription"],
    invoices: () => [...paymentKeys.all, "invoices"],
    customerPortalSession: () => [...paymentKeys.all, "customer-portal"],
};
