import { Box, Button, capitalize, Dialog, Divider, IconButton, Typography } from "@mui/material";
import { useLatestSubscription, usePricings } from "hooks/api/payments/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import CheckIcon from "../Icons/CheckIcon";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useUpgradeSubscription } from "hooks/api/payments/mutations";
import Loader from "../Loader";

function SubscriptionCard({ price, subscriptionId }) {
    const { md } = useCustomMediaQuery();
    const upgradeSubscription = useUpgradeSubscription();
    let priceDescription, unsubscribeLimit, bulkDeleteLimit;
    if (price.nickname === "basic") {
        priceDescription = "Monthly subscription with actions for upto 50 senders.";
        unsubscribeLimit = "50 senders";
        bulkDeleteLimit = "50 senders";
    } else {
        priceDescription = "Annual plan with unlimited actions.";
        unsubscribeLimit = "Unlimited";
        bulkDeleteLimit = "Unlimited";
    }
    return (
        <Box
            sx={{
                background: "linear-gradient(180.69deg, #E2E5FD -130.74%, #FFFFFF 74.9%)",
                boxShadow: "0px 10px 25px 0px #FFEB9A80",
                px: "16px",
                pt: "16px",
                pb: "40px",
                borderRadius: "12px",
            }}
        >
            {upgradeSubscription.isPending && <Loader />}
            <Box>
                <Typography fontSize={"20px"} fontWeight={700}>
                    {capitalize(price.nickname)}
                </Typography>
                <Typography variant="body2">{priceDescription}</Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={"10px"} mt={"24px"} mb={md ? 0 : "24px"}>
                <Typography fontWeight={600} fontSize={md ? "32px" : "44px"}>
                    ${price.unit_amount / 100}
                </Typography>
                <Typography fontWeight={300}>/ {capitalize(price.interval)}</Typography>
            </Box>
            <Box
                display={"flex"}
                alignItems={md ? "center" : "flex-start"}
                justifyContent={"space-between"}
                flexDirection={md ? "row" : "column-reverse"}
            >
                <Box marginTop={"16px"} display={"flex"} flexDirection={"column"} gap={md ? "8px" : "12px"}>
                    <Box display={"flex"} gap={md ? "8px" : "17px"}>
                        <Box>
                            <CheckIcon size={md ? 20 : 32} />
                        </Box>
                        <Typography variant={"body2"} fontWeight={500}>
                            Auto-Cleaner
                        </Typography>
                    </Box>
                    <Box display={"flex"} gap={md ? "8px" : "17px"}>
                        <Box>
                            <CheckIcon size={md ? 20 : 32} />
                        </Box>
                        <Typography fontWeight={500} variant={"body2"}>
                            Bulk Delete{" "}
                            <Typography
                                fontWeight={500}
                                component={"span"}
                                display={"inline"}
                                sx={{ color: "#4B5768" }}
                                variant={"body2"}
                            >
                                ({bulkDeleteLimit})
                            </Typography>
                        </Typography>
                    </Box>
                    <Box display={"flex"} gap={md ? "8px" : "17px"} alignItems={"center"}>
                        <Box>
                            <CheckIcon size={md ? 20 : 32} />
                        </Box>
                        <Typography lineHeight={"19.36px"} fontWeight={500} variant={"body2"}>
                            Bulk Unsubscribe{" "}
                            <Typography
                                variant={"body2"}
                                fontWeight={500}
                                lineHeight={"19.36px"}
                                display={"inline"}
                                component={"span"}
                                sx={{ color: "#4B5768" }}
                            >
                                ({unsubscribeLimit})
                            </Typography>
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: md ? "170px" : "100%",
                    }}
                >
                    <Button
                        variant="contained"
                        disableElevation
                        disableRipple
                        fullWidth
                        sx={{
                            background: "#0D0E23",
                            color: "white",
                            height: "42px",
                        }}
                        onClick={() => {
                            upgradeSubscription.mutate(
                                { id: subscriptionId, newPriceId: price.id },
                                {
                                    onSuccess: (checkoutSession) => {
                                        window.location = checkoutSession.subscription_update_confirm_url;
                                    },
                                },
                            );
                        }}
                        data-testid={`subscribe-${price.nickname}`}
                    >
                        Subscribe
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

const newPricingNicknames = ["basic", "power"];

export default function SubscriptionLimitDialog({ open, setOpen, variant }) {
    const { md } = useCustomMediaQuery();
    const { data: prices, isPending } = usePricings();
    const { data: subsciption, isPending: subsciptionPending } = useLatestSubscription();
    if (isPending || subsciptionPending) {
        return null;
    }
    return (
        <Dialog
            fullScreen={!md}
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: md ? "600px" : "100%",
                    },
                },
            }}
            PaperProps={{
                style: {
                    background: "#EFBA42",
                },
            }}
        >
            <Box padding={"20px"} display={"flex"} justifyContent={"space-between"} alignItems={"flex-start"}>
                <Box>
                    <Typography gutterBottom fontWeight={600} variant={md ? "body1" : "body2"}>
                        {subsciption?.price.nickname === "freebie"
                            ? `You have ${variant} 10 senders with free subscription.`
                            : `You have ${variant} 50 senders with basic subscription.`}
                    </Typography>
                    <Typography fontSize={md ? "14px" : "12px"}>Upgrade you subsciption below</Typography>
                </Box>
                {!md && (
                    <IconButton
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CancelOutlinedIcon
                            sx={{
                                color: "#1D1B20",
                            }}
                        />
                    </IconButton>
                )}
            </Box>
            <Divider
                sx={{
                    border: "1px solid #0000004D",
                }}
            />
            <Box padding={"20px"}>
                <Typography marginBottom={"18px"} fontSize={"14px"} fontWeight={500}>
                    Upgrade to the Power Subscription and gain the ability to remove unlimited unwanted senders from
                    your inbox.
                </Typography>
                <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
                    {prices
                        .filter(
                            (p) =>
                                newPricingNicknames.includes(p.nickname) && subsciption?.price.nickname !== p.nickname,
                        )
                        .map((price) => (
                            <SubscriptionCard key={price.id} price={price} subscriptionId={subsciption?.id} />
                        ))}
                </Box>
            </Box>
            <Box display={"flex"} justifyContent={"center"} my={"20px"}>
                <Button
                    disableElevation
                    disableRipple
                    onClick={() => {
                        setOpen(false);
                    }}
                    variant="text"
                    sx={{
                        color: "#1F2337",
                        fontSize: "14px",
                        fontWeight: 600,
                    }}
                    data-testid="subscription-limit-cancel-button"
                >
                    Cancel
                </Button>
            </Box>
        </Dialog>
    );
}
