import { useEffect, useRef, useState } from "react";
import { useMailBotMetadata, useMailBotStatistics } from "hooks/api/mailbot/queries";
import { usePusher } from "./usePusher";
import { useQueryClient } from "@tanstack/react-query";
import { mailbotKeys } from "queryKeyFactory";

const showOnboardingLoaderTillSeconds = 600; // Show onboarding loader for maximum 10 minutes
const showOnboardingLoaderTillMessages = 5000; // Show onboarding loader till 5000 messages are scanned

export default function useOnboardingLoader() {
    const [showOnboardingLoader, setShowOnboardingLoader] = useState(false);
    const [showScreenAfterOnboarding, setShowScreenAfterOnboarding] = useState(false);
    const { data: statistics, isPending: isStatisticsPending } = useMailBotStatistics();
    const { data: mailbotMetadata, isPending: mailbotMetadataPending } = useMailBotMetadata();
    const onboardingLoaderTimeRef = useRef(null) as any;
    const queryClient = useQueryClient();
    if (
        !isStatisticsPending &&
        showOnboardingLoader &&
        statistics.messages_scanned >= showOnboardingLoaderTillMessages
    ) {
        setShowOnboardingLoader(false);
        setShowScreenAfterOnboarding(true);
    }

    usePusher("sender-profiles", "data", (data) => {
        const senderProfilesQueryKey = mailbotKeys.autoCleanerPage({
            page: 1,
            queryParams: { ordering: "-total_count,sender_email" },
        });
        queryClient.setQueryData(senderProfilesQueryKey, data.sender_profiles);
        queryClient.setQueryData(mailbotKeys.statistics(), data.statistics_data);
    });

    usePusher("sender-profiles", "onboarding-complete", (_: any) => {
        if (showOnboardingLoader) {
            setShowOnboardingLoader(false);
            setShowScreenAfterOnboarding(true);
        }
        queryClient.invalidateQueries({ queryKey: mailbotKeys.mailbotProfile() });
        queryClient.invalidateQueries({ queryKey: mailbotKeys.statistics() });
        queryClient.invalidateQueries({ queryKey: mailbotKeys.senderProfiles() });
    });

    useEffect(() => {
        if (!isStatisticsPending && !mailbotMetadataPending) {
            if (mailbotMetadata.onboarding_failed === true || mailbotMetadata.onboarding_completed === true) {
                if (showOnboardingLoader) {
                    setShowOnboardingLoader(false);
                    setShowScreenAfterOnboarding(true);
                }
            } else {
                // onboarding is still in progress, check time since mailbot was last enabled and messages scanned
                let secondsSinceMailBotLastEnabled = 0;
                if (!!mailbotMetadata.last_enabled_at) {
                    secondsSinceMailBotLastEnabled =
                        (new Date().getTime() - new Date(mailbotMetadata.last_enabled_at).getTime()) / 1000;
                }
                let messages_scanned = 0;
                if (!!statistics.messages_scanned) {
                    messages_scanned = statistics.messages_scanned;
                }
                if (
                    secondsSinceMailBotLastEnabled < showOnboardingLoaderTillSeconds &&
                    messages_scanned < showOnboardingLoaderTillMessages
                ) {
                    setShowOnboardingLoader(true);
                    // Set onboarding to complete again after 10 minutes since the last time mailbot was enabled
                    onboardingLoaderTimeRef.current = setTimeout(
                        () => {
                            if (showOnboardingLoader) {
                                setShowOnboardingLoader(false);
                                setShowScreenAfterOnboarding(true);
                            }
                        },
                        (showOnboardingLoaderTillSeconds - secondsSinceMailBotLastEnabled) * 1000,
                    );
                }
            }
        }
        return () => {
            if (onboardingLoaderTimeRef.current) {
                clearTimeout(onboardingLoaderTimeRef.current);
            }
        };
    }, [
        mailbotMetadata?.onboarding_completed,
        mailbotMetadata?.onboarding_failed,
        isStatisticsPending,
        mailbotMetadataPending,
        mailbotMetadata?.last_enabled_at,
        statistics?.messages_scanned,
    ]);
    // We show loader upto 5000 mails, so (x / 5000) * 100 = x / 50
    const inboxScannedPercentage = isStatisticsPending ? 0 : ((statistics.messages_scanned || 0) / 50).toFixed(0);
    return {
        showOnboardingLoader,
        inboxScannedPercentage,
        showScreenAfterOnboarding,
        setShowScreenAfterOnboarding,
    };
}
