import { Box, Dialog, Typography } from "@mui/material";
import PricingCatalogNew from "components/Subscription/PricingCatalogNew";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

export default function SubscriptionSetup({ open, setOpen }) {
    const { md } = useCustomMediaQuery();
    return (
        <Dialog open={open} fullScreen fullWidth>
            <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                marginTop={md ? "144px" : "16px"}
                marginBottom={md ? 0 : "16px"}
            >
                <Box maxWidth={md ? "916px" : "343px"} width={"100%"}>
                    <Typography fontSize={md ? "40px" : "20px"} fontWeight={600}>
                        You're almost there! Want to upgrade your subscription before checking your emails?
                    </Typography>
                    <Box marginTop={md ? "65px" : "20px"}>
                        <PricingCatalogNew
                            handleCancel={() => {}}
                            handleRenew={() => {}}
                            usedInOnboarding={true}
                            onContinue={() => {
                                setOpen(false);
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
}
